import '@babel/polyfill'
import 'core-js/fn/array/flat-map'
import 'vuetify/src/stylus/app.styl'
import './@styles/main.styl'
import Vue from 'vue'
import Vuetify from 'vuetify'
import VueGtag from 'vue-gtag'
import VueApexCharts from 'vue-apexcharts'
import store from './modules'
import router from './router'
import App from './App.vue'
import AppImg from './components/AppImg.vue'
import PageViewIcon from './components/PageViewIcon.vue'
import FacebookIcon from './components/FacebookIcon.vue'
import TwitterIcon from './components/TwitterIcon.vue'
import MailchimpIcon from './components/MailchimpIcon.vue'
import InstagramIcon from './components/InstagramIcon.vue'
import FrequentShopperIcon from './components/FrequentShopperIcon.vue'
import ScissorsIcon from './components/ScissorsIcon.vue'
import YelpIcon from './components/YelpIcon.vue'
import dateFilter from './filters/DateFilter'
import brDataDateFilter from './filters/BRdataDateFilter'
import truncateFilter from './filters/TruncateFilter'
import currencyFilter from './filters/CurrencyFilter'
import FontFaceObserver from 'fontfaceobserver'

Vue.config.productionTip = false

Vue.filter('date', dateFilter)
Vue.filter('brdataDate', brDataDateFilter)
Vue.filter('truncate', truncateFilter)
Vue.filter('currency', currencyFilter)

Vue.component('app-img', AppImg)

Vue.use(Vuetify, {
  icons: {
    page_view: { component: PageViewIcon },
    facebook: { component: FacebookIcon },
    twitter: { component: TwitterIcon },
    instagram: { component: InstagramIcon },
    freqshopper: { component: FrequentShopperIcon },
    scissors: { component: ScissorsIcon },
    yelp: { component: YelpIcon },
    mailchimp: { component: MailchimpIcon }
  }
})

Vue.use(VueGtag, {
  bootstrap: false
}, router)

Vue.use(VueApexCharts)

const root = new Vue({
  router,
  store,
  render: $ => $(App)
})

new FontFaceObserver('Material Icons')
  .load()
  .catch((e: string) => console.log('Error loading font: ' + e))
  .finally(() => root.$mount('#app'))
