import { CancelToken } from 'axios';
import { BaseApi } from './BaseApi';

export default class Ads extends BaseApi {
  public async getByStoreRouteAsync(itemView?: boolean, cancelToken?: CancelToken) {
    return this.getListAsync<IAd>('content', { itemView }, cancelToken);
  }

  public async getByAdIdAsync(adId: number) {
    return this.getAsync<IAd>('content/' + adId);
  }

  public async getForAdminAsync(includeItems?: boolean, cancelToken?: CancelToken) {
    return this.getListAsync<IAd>('content/admin', { includeItems }, cancelToken);
  }

  public async viewAsync(adId: number) {
    await this.putAsync<IAd>(`content/${adId}/view`);
  }

  public async createAsync(ad: IAd) {
    await this.postAsync<IAd>('content', ad);
  }

  public async updateAsync(ad: IAd) {
    await this.putAsync<IAd>('content/' + ad.adId, ad);
  }

  public async deleteAsync(adId: number) {
    await this.$deleteAsync('content/' + adId);
  }
}
