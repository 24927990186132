import api from '../api';
import { Mutation } from './ModuleFactory';
import reduce from 'lodash/reduce';
import { CancelToken } from 'axios';

export default class StoreModule {

  private _store = null as IStore;
  public get store() {
    return this._store;
  }

  private _storeOptions = {} as { [storeOptionTypeToken: string]: string };
  public get storeOptions() {
    return this._storeOptions;
  }

  private _chainStores = [] as IStore[];
  public get chainStores() {
    return this._chainStores;
  }

  private _ads = [] as IAd[];
  public get ads() {
    return this._ads;
  }

  private _categories = [] as ICategory[];
  public get categories() {
    return this._categories;
  }

  private _measurementId = null as string;
  public get measurementId() {
    return this._measurementId;
  }

  @Mutation
  private initialize(store: IStore, chainStores: IStore[], storeOptions: IStoreOption[], ads: IAd[], categories: ICategory[], measurementId: string) {
    this._store = store;
    this._chainStores = chainStores;
    this._storeOptions = reduce(storeOptions, (r, n) => { r[n.storeOptionTypeToken] = n.value; return r; }, {});
    this._ads = ads;
    this._categories = categories;
    this._measurementId = measurementId;
  }

  public async loadStoreAsync(storeRoute: string, onlyLoadItemView?: boolean, cancelToken?: CancelToken) {
    if (!storeRoute) {
      throw new Error('ERROR: Expected storeRoute to have a value.');
    }

    if (!this._store || this._store.storeRoute !== storeRoute) {
      await api.all(
        api.stores.getChainAsync(cancelToken),
        api.storeOptions.getForStoreAsync(cancelToken),
        api.ads.getByStoreRouteAsync(onlyLoadItemView, cancelToken),
        api.categories.getByStoreRouteAsync(cancelToken),
        api.googleAnalytics.getMeasurementIdAsync(cancelToken),
        (stores, storeOptions, ads, categories, measurementId) => {
          this.initialize(stores.find(s => s.storeRoute === storeRoute), stores, storeOptions, ads, categories, measurementId);
        });
    }
  }
}
