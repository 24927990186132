import api from '../api'
import { Mutation } from './ModuleFactory'
import { event } from 'vue-gtag'

export default class AdModule {
  private _ad = null as IAd
  public get ad() {
    return this._ad
  }
  public set ad(value: IAd) {
    this._ad = value
  }

  private _pageNumber = 1
  public get pageNumber() {
    return this._pageNumber
  }
  public set pageNumber(value: number) {
    if (!this._ad || !this._ad.adPages) {
      throw new Error('ERROR: Cannot set pageNumber with no ad.')
    }
    if (value < 1 || value > this._ad.adPages.length) {
      throw new Error('ERROR: pageNumber out of range.')
    }
    this._pageNumber = value
    event('View', { event_category: 'Ad Pages', event_label: this._ad.title, value })
  }

  private _categories = [] as ICategory[]
  public get categories() {
    return this._categories
  }
  public set categories(value: ICategory[]) {
    this._categories = value
  }

  @Mutation
  private viewAd() {
    this._ad.viewCount++
  }

  public async viewAdAsync() {
    this.viewAd()
    await api.ads.viewAsync(this.ad.adId)
  }
}
