const locale = navigator.language

export default function(
  value: string | Date | null,
  options?: Intl.DateTimeFormatOptions | string,
  timeZone?: string
) {
  // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
  if (!value) {
    return ''
  }
  let date = new Date(value)

  if (options === 'time') {
    return date.toLocaleTimeString(locale, {
      timeZone,
      hour: 'numeric',
      minute: 'numeric'
    })
  }
  if (options === 'time-picker') {
    return date.toLocaleTimeString(locale, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
  }

  if (options === 'short') {
    return date.toLocaleDateString(locale, {
      timeZone,
      month: 'short',
      year: 'numeric',
      day: 'numeric'
    })
  }
  if (options === 'date-picker') {
    const [month, day, year] = date
      .toLocaleDateString('en-US', {
        month: '2-digit',
        year: 'numeric',
        day: '2-digit'
      })
      .split('/')
    return `${year}-${month}-${day}`
  }
  if (options === 'long') {
    return date.toLocaleDateString(locale, {
      timeZone,
      month: 'short',
      year: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    })
  }
  const formatOptions = options as Intl.DateTimeFormatOptions
  formatOptions.timeZone = timeZone
  return date.toLocaleDateString(locale, formatOptions)
}
