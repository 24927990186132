export default function (value: any) {
  if (!value) {
    return '$0';
  }
  const val = +value;
  if (Number.isNaN(val)) {
    return value;
  }
  if (val < 1) {
    return val * 100 + '¢';
  }
  return '$' + val.toFixed(2);
}
