import { CancelToken } from 'axios';
import { BaseApi } from './BaseApi';

export default class BRdata extends BaseApi {
  public async loginAsync(appId: string, email: string, password: string, isPersistent?: boolean, cancelToken?: CancelToken) {
    return this.postAsync<IAuthenticationResult>(`brdata/${appId}/login`, { email, password, isPersistent }, null, cancelToken);
  }

  public async loginWithTokenAsync(appId: string, token: string, cancelToken?: CancelToken) {
    return this.postAsync<IAuthenticationResult>(`brdata/${appId}/login`, { token }, null, cancelToken);
  }

  public async getStoresAsync(appId: string, cancelToken?: CancelToken) {
    return this.getListAsync<IBRdataStore>(`brdata/${appId}/stores`, null, cancelToken);
  }

  public async getEmailSubscriptionsAsync(appId: string, cancelToken?: CancelToken) {
    return this.getListAsync<IBRdataEmailSubscription>(`brdata/${appId}/emailsubscriptions`, null, cancelToken);
  }

  public async getSecurityQuestionsAsync(appId: string, cancelToken?: CancelToken) {
    return this.getListAsync<string>(`brdata/${appId}/securityquestions`, null, cancelToken);
  }

  public async getUserSecurityQuestionAsync(appId: string, email: string, cancelToken?: CancelToken) {
    return this.getAsync<string>(`brdata/${appId}/securityquestions`, { email }, cancelToken);
  }

  public async registerAsync(appId: string, registrationInfo: IBRdataRegistrationInfo, cancelToken?: CancelToken) {
    return this.postAsync<string>(`brdata/${appId}/register`, registrationInfo, null, cancelToken);
  }

  public async updateEmailSubscriptionsAsync(appId: string, emailSubscriptions: IBRdataEmailSubscription[], cancelToken?: CancelToken) {
    await this.putAsync(`brdata/${appId}/emailsubscriptions`, emailSubscriptions, null, cancelToken);
  }

  public async verifySecurityAnswerAsync(appId: string, email: string, securityQuestion: string, securityAnswer: string) {
    return this.getAsync<Boolean>(`brdata/${appId}/verifysecurityanswer`, { email, securityQuestion, securityAnswer });
  }

  public async resetPasswordAsync(appId: string, email: string, password: string) {
    await this.putAsync(`brdata/${appId}/resetpassword`, { email, password });
  }

  public async getShoppingListsAsync(appId: string, cancelToken?: CancelToken) {
    return this.getListAsync<IShoppingList>(`brdata/${appId}/shoppinglists`, null, cancelToken);
  }

  public async createShoppingListAsync(appId: string, shoppingList: IShoppingList, cancelToken?: CancelToken) {
    return this.postAsync<IShoppingList>(`brdata/${appId}/shoppinglists`, shoppingList, null, cancelToken);
  }

  public async deleteShoppingListAsync(appId: string, shoppingListId: number, cancelToken?: CancelToken) {
    await this.$deleteAsync(`brdata/${appId}/shoppinglists/${shoppingListId}`, null, cancelToken);
  }

  public async createShoppingListItemAsync(appId: string, shoppingListItem: IShoppingListItem, cancelToken?: CancelToken) {
    return this.postAsync<IShoppingListItem>(`brdata/${appId}/shoppinglistitems`, shoppingListItem, null, cancelToken);
  }

  public async updateShoppingListItemAsync(appId: string, shoppingListItem: IShoppingListItem, cancelToken?: CancelToken) {
    await this.putAsync(`brdata/${appId}/shoppinglistitems/${shoppingListItem.shoppingListItemId}`, shoppingListItem, null, cancelToken);
  }

  public async deleteShoppingListItemAsync(appId: string, shoppingListItemId: number, cancelToken?: CancelToken) {
    await this.$deleteAsync(`brdata/${appId}/shoppinglistitems/${shoppingListItemId}`, null, cancelToken);
  }

  public async getUserAsync(appId: string) {
    return this.getAsync<IBRdataUser>(`brdata/${appId}/user`);
  }

  public async getCouponsAsync(appId: string) {
    return this.getAsync<{ activated: IBRdataCoupon[], available: IBRdataCoupon[] }>(`brdata/${appId}/coupons`);
  }

  public async addCouponAsync(appId: string, offerIds: string[]) {
    return this.postAsync(`brdata/${appId}/coupons`, offerIds);
  }
}
