import { BaseApi } from './BaseApi'

export default class SocialMediaMessages extends BaseApi {
  public async createAsync(message: ISocialMediaMessage, adRoute: string) {
    return await this.postAsync<ISocialMediaMessage>(
      'socialmediamessages',
      message,
      { adRoute }
    )
  }

  public async updateAsync(message: ISocialMediaMessage) {
    await this.putAsync('socialmediamessages/' + message.messageId, message)
  }

  public async deleteAsync(messageId: number) {
    await this.$deleteAsync('socialmediamessages/' + messageId)
  }

  public async getScheduledAsync() {
    return await this.getListAsync<ISocialMediaMessage>(
      'socialmediamessages/scheduled'
    )
  }

  public async getMailchimpCampaigns() {
    return await this.getListAsync<IMailchimpCampaign>(
      'socialmediamessages/mailchimp/campaigns'
    )
  }
}
