import api from '../api'
import assign from 'lodash/assign'
import { CancelToken } from 'axios'
import { Mutation } from './ModuleFactory'
import reduce from 'lodash/reduce'

export default class AdminModule {
  private _store = null as IStore
  public get store() {
    return this._store
  }

  private _storeOptions = {} as { [storeOptionTypeToken: string]: string }
  public get storeOptions() {
    return this._storeOptions
  }

  private _socialMediaCredentials = null as ISocialMediaCredentials | null
  public get socialMediaCredentials() {
    return this._socialMediaCredentials
  }

  private _stateProvinces = null as IStateProvince[]
  public get stateProvinces() {
    return this._stateProvinces
  }

  private _customerApps = null as ICustomerApp[]
  public get customerApps() {
    return this._customerApps
  }

  @Mutation
  private initialize(
    store: IStore,
    storeOptions: IStoreOption[],
    socialMediaCredentials: ISocialMediaCredentials,
    stateProvinces: IStateProvince[],
    customerApps: ICustomerApp[]
  ) {
    this._store = store
    this._storeOptions = reduce(
      storeOptions,
      (r, n) => {
        r[n.storeOptionTypeToken] = n.value
        return r
      },
      {}
    )
    this._socialMediaCredentials = socialMediaCredentials
    this._stateProvinces = stateProvinces
    this._customerApps = customerApps
  }

  @Mutation
  private updateStore(newValues: IStore) {
    assign(this._store, newValues)
  }

  @Mutation
  private updateSocialMediaCredentials(newValues: ISocialMediaCredentials) {
    if (!this._socialMediaCredentials) {
      this._socialMediaCredentials = newValues
    } else {
      assign(this._socialMediaCredentials, newValues)
    }
  }

  public async loadAsync(storeRoute: string, cancelToken?: CancelToken) {
    if (!storeRoute) {
      throw new Error('ERROR: Expected storeRoute to have a value.')
    }

    await api.all(
      api.stores.getByStoreRouteAsync(cancelToken),
      api.storeOptions.getForStoreAsync(cancelToken),
      api.socialMediaCredentials.getByStoreRouteAsync(cancelToken),
      api.stateProvinces.getAllAsync(cancelToken),
      api.customerApps.getAllAsync(cancelToken),
      (store, storeOptions, socialMediaCredentials, stateProvinces, customerApps) => {
        this.initialize(
          store,
          storeOptions,
          socialMediaCredentials,
          stateProvinces,
          customerApps
        )
      }
    )
  }

  public async updateStoreAsync(newValues: IStore) {
    this.updateStore(newValues)
    await api.stores.updateAsync(this._store)
  }

  public async postAdAsync(ad: IAd) {
    await api.ads.createAsync(ad)
  }

  public async saveAdAsync(ad: IAd) {
    await api.ads.updateAsync(ad)
  }

  public async deleteAdAsync(adId: number) {
    await api.ads.deleteAsync(adId)
  }

  public async getAdsForAdminAsync(includeItems?: boolean) {
    return await api.ads.getForAdminAsync(includeItems)
  }

  public async getScheduledSocialMediaMessagesAsync() {
    return await api.socialMediaMessages.getScheduledAsync()
  }

  public async saveSocialMediaMessageAsync(message: ISocialMediaMessage) {
    await api.socialMediaMessages.updateAsync(message)
  }

  public async deleteSocialMediaMessageAsync(message: ISocialMediaMessage) {
    await api.socialMediaMessages.deleteAsync(message.messageId)
  }

  public async getMediaStudioAccounts() {
    return await api.mediaStudio.getAccountsAsync(this._store.storeGuid)
  }

  public async disconnectAccountPlatform(accountPlatformId: number) {
    return await api.mediaStudio.disconnectAccountPlatformAsync(accountPlatformId)
  }

  public async getMediaStudioPlatforms() {
    return await api.mediaStudio.getPlatformsAsync()
  }

  public async getBannerAdsForAdminAsync() {
    return await api.bannerAds.getForAdminAsync()
  }

  public getStoreHasApp(appName: string) {
    return this.customerApps.filter(app => app.appName == appName).length > 0;
  }

  public async updateBannerAdOptOut(bannerAd: IBannerAd) {
    if (bannerAd.isOptedOut) {
      await api.bannerAds.optOutAsync(bannerAd.bannerAdId)
    } else {
      await api.bannerAds.optInAsync(bannerAd.bannerAdId)
    }
  }

  public async shortenUrlAsync(url: string) {
    return await api.bitly.shortenUrlAsync(url)
  }

  public async createSocialMediaCredentialsAsync(
    credentials: ISocialMediaCredentials
  ) {
    this.updateSocialMediaCredentials(
      await api.socialMediaCredentials.createAsync(credentials)
    )
  }

  public async updateSocialMediaCredentialsAsync(
    newValues: ISocialMediaCredentials
  ) {
    this.updateSocialMediaCredentials(newValues)
    await api.socialMediaCredentials.updateAsync(this._socialMediaCredentials)
  }

  public async getTwitterOAuthTokenAsync() {
    return await api.socialMediaCredentials.getTwitterOAuthTokenAsync()
  }

  public async getMailchimpOAuthURLAsync() {
    return await api.socialMediaCredentials.getMailchimpOAuthURL()
  }

  public async getMailchimpCampaigns() {
    return await api.socialMediaMessages.getMailchimpCampaigns()
  }

  public async shareAdAsync(message: ISocialMediaMessage, adRoute: string) {
    assign(message, await api.socialMediaMessages.createAsync(message, adRoute))
  }

  public async updateAdSpaceAsync(adSpace: IAdSpace) {
    await api.adSpaces.updateAsync(adSpace)
  }
}
