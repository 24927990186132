







import { Component, Prop, Mixins } from 'vue-property-decorator';
import AppMixin from '../mixins/AppMixin';

@Component({ inheritAttrs: false })
export default class ComponentSwitcher extends Mixins(AppMixin) {
  @Prop() desktop!: string;
  @Prop() mobile!: string;
  @Prop() iframeDesktop!: string;
  @Prop() iframeMobile!: string;

  get component() {
    return this.app.isMobile
      ? (this.app.isIframe && this.iframeMobile) || this.mobile
      : (this.app.isIframe && this.iframeDesktop) || this.desktop;
  }
}
