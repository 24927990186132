import { CancelToken } from 'axios';
import { CacheableBaseApi } from './BaseApi';

export default class ShoppingLists extends CacheableBaseApi<IShoppingList> {
  constructor() {
    super('shoppinglists', 'shoppingListId', ['items']);
  }

  public async getAllAsync(cancelToken?: CancelToken) {
    if (this.cache) {
      return this.cache.slice(0);
    }
    return this.getListAsync<IShoppingList>('shoppinglists', null, cancelToken);
  }

  public async createAsync(shoppingList: IShoppingList) {
    if (this.cache) {
      return this.addToCache(shoppingList);
    }
    return this.postAsync<IShoppingList>('shoppinglists', shoppingList);
  }

  public async updateAsync(shoppingList: IShoppingList) {
    if (this.cache) {
      this.persistCache();
    } else {
      await this.putAsync<IShoppingList>('shoppinglists', shoppingList);
    }
  }

  public async deleteAsync(shoppingList: IShoppingList) {
    if (this.cache) {
      this.removeFromCache(shoppingList);
    } else {
      await this.$deleteAsync('shoppinglists/' + shoppingList.shoppingListId);
    }
  }

  public async emailAsync(shoppingList: IShoppingList, address: string, includeImages?: boolean, sortBy?: string) {
    return this.postAsync<IShoppingList>('shoppinglists/email', shoppingList, {
      address,
      includeImages,
      sortBy
    });
  }
}
