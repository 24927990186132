import { render, staticRenderFns } from "./AdBlockerWarningDialog.vue?vue&type=template&id=9ba2e9f2&"
import script from "./AdBlockerWarningDialog.vue?vue&type=script&lang=ts&"
export * from "./AdBlockerWarningDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VIcon,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("c:\\source\\adViewer\\AdViewer.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9ba2e9f2')) {
      api.createRecord('9ba2e9f2', component.options)
    } else {
      api.reload('9ba2e9f2', component.options)
    }
    module.hot.accept("./AdBlockerWarningDialog.vue?vue&type=template&id=9ba2e9f2&", function () {
      api.rerender('9ba2e9f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "wwwroot/src/components/AdBlockerWarningDialog.vue"
export default component.exports