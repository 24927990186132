import { BaseApi } from './BaseApi';
import { CancelToken } from 'axios';

export default class Stores extends BaseApi {
  public async getByStoreRouteAsync(cancelToken?: CancelToken) {
    return await this.getAsync<IStore>('stores', null, cancelToken);
  }

  public async getChainAsync(cancelToken?: CancelToken) {
    return await this.getListAsync<IStore>('stores/chain', null, cancelToken);
  }

  public async updateAsync(store: IStore) {
    await this.putAsync<IStore>('stores', store);
  }
}
