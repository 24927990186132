import { CancelToken } from 'axios'
import { BaseApi } from './BaseApi'

export default class GoogleAnalytics extends BaseApi {
  public async getMeasurementIdAsync(cancelToken?: CancelToken) {
    return this.getAsync<string>(
      'googleanalytics/measurementid',
      null,
      cancelToken
    )
  }
  public async getByStoreRouteAsync(
    startDate?: string,
    endDate?: string,
    cancelToken?: CancelToken
  ) {
    return this.getAsync<IGoogleAnalytics>(
      'googleanalytics',
      { startDate, endDate },
      cancelToken
    )
  }
}
