import { Store, Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import has from 'lodash/has';
import snakeCase from 'lodash/snakeCase';
import keyBy from 'lodash/keyBy';

interface IModuleConstructor<M> extends Function {
  new(...args: any[]): M;
  definition?: IModuleDefinition<M>;
  $mutations?: any;
}

interface IModuleDefinition<M> extends Module<M, any> {
  name?: string;
  getters: GetterTree<M, any>;
  actions: ActionTree<M, any>;
  mutations: MutationTree<M>;
}

// inspired by https://github.com/championswimmer/vuex-module-decorators
export default class ModuleFactory {
  constructor(
    private readonly store: Store<any>) {
  }

  createModule<M>(name: string, module: IModuleConstructor<M>) {

    if (!module.definition) {
      module.definition = {
        getters: {},
        mutations: {},
        actions: {}
      };
      module.definition.name = name;

      Object.getOwnPropertyNames(module.prototype).forEach(key => {
        if (key === 'constructor') {
          return;
        }
        const descriptor = Object.getOwnPropertyDescriptor(module.prototype, key);
        if (!descriptor) {
          return;
        }
        if (descriptor.get) {
          module.definition.getters[key] = function (state: any) {
            return (descriptor.get as Function).call(state);
          };
        }
        if (descriptor.set) {
          module.definition.mutations[key] = function (state: any, payload: any) {
            (descriptor.set as Function).call(state, payload);
          };
        }
        if (typeof descriptor.value === 'function') {
          if (module.$mutations && has(module.$mutations, key)) {
            module.definition.mutations['$' + key] = module.$mutations[key];
          } else {
            module.definition.actions[key] = descriptor.value;
          }
        }
      });
      delete module.$mutations;
    }

    const path = module.definition.name;
    const instance = {
      namespaced: true,
      state: {},
      getters: {},
      mutations: {}
    } as any;
    const initialState = new module.prototype.constructor();
    Object.keys(initialState).forEach(key => {
      instance.state[key] = initialState[key];
      Object.defineProperty(instance, key, {
        get: () => {
          return this.store.state[path][key];
        }
      });
    });
    Object.keys(module.definition.getters).forEach(key => {
      instance.getters[key] = module.definition.getters[key];
      if (has(module.definition.mutations, key)) {
        const mutationName = snakeCase(key).toUpperCase() + '_CHANGED';
        instance.mutations[mutationName] = module.definition.mutations[key];
        Object.defineProperty(instance, key, {
          get: () => {
            return this.store.getters[`${path}/${key}`];
          },
          set: (...args: any[]) => {
            this.store.commit(`${path}/${mutationName}`, ...args);
          }
        });
      } else {
        Object.defineProperty(instance, key, {
          get: () => {
            return this.store.getters[`${path}/${key}`];
          }
        });
      }
    });
    Object.keys(module.definition.mutations).filter(key => !has(module.definition.getters, key)).forEach(key => {
      if (key[0] === '$') {
        const mutationName = snakeCase(key.substring(1)).toUpperCase();
        instance.mutations[mutationName] = module.definition.mutations[key];
        Object.defineProperty(instance, key.substring(1), {
          value: (...args: any[]) => {
            this.store.commit(`${path}/${mutationName}`, keyBy(args, v => args.indexOf(v)));
          }
        });
      } else {
        const mutationName = snakeCase(key).toUpperCase() + '_CHANGED';
        instance.mutations[mutationName] = module.definition.mutations[key];
        Object.defineProperty(instance, key, {
          set: (...args: any[]) => {
            this.store.commit(`${path}/${mutationName}`, ...args);
          }
        });
      }
    });
    Object.keys(module.definition.actions).forEach(key => {
      Object.defineProperty(instance, key, {
        value: module.definition.actions[key]
      });
    });

    this.store.registerModule(path, instance);
    this.store[path] = instance;
    return instance;
  }

  destroyModule(module: any) {
    const path = module.definition.name;
    if (this.store[path]) {
      this.store.unregisterModule(path);
      delete this.store[path];
    }
  }
}

export function Mutation<T>(
  target: T,
  key: string | symbol,
  descriptor: TypedPropertyDescriptor<(...args: any[]) => any>
) {
  const module = target.constructor as IModuleConstructor<T>;
  if (!module.$mutations) {
    module.$mutations = {};
  }
  const func = descriptor.value as Function;
  const mutation = function (state: typeof target, payload: any) {
    if (typeof payload === 'object') {
      const args = Object.keys(payload).sort().map(key => payload[key]);
      func.call(state, ...args);
    } else {
      func.call(state, payload);
    }
  };
  module.$mutations[key as string] = mutation;
}
