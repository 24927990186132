var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.app.isMobile
    ? _c(
        "keep-alive",
        [
          _c(
            _vm.component,
            _vm._b({ tag: "component" }, "component", _vm.$attrs, false)
          )
        ],
        1
      )
    : _c(
        _vm.component,
        _vm._b({ tag: "component" }, "component", _vm.$attrs, false)
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }