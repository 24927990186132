var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-img", {
    staticClass: "frequent-shopper-icon",
    class: {
      "frequent-shopper-icon--dark":
        _vm.storeOptions["FREQUENT_SHOPPER_LOGO_DARK"]
    },
    attrs: {
      src: _vm.pathToImages(
        "./" + _vm.storeOptions["FREQUENT_SHOPPER_LOGO_URI"]
      ).default,
      contain: "",
      width: _vm.storeOptions["FREQUENT_SHOPPER_LOGO_WIDTH"] || 40
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }