export default class LocalStorage {
  private readonly _isAvailable: boolean;
  private readonly _id: string;
  constructor(id: string) {
    this._id = id;
    try {
      localStorage.setItem('__test__', '');
      localStorage.removeItem('__test__');
      this._isAvailable = true;
    } catch {
      this._isAvailable = false;
    }
  }

  public get<T>(key: string) {
    if (!this._isAvailable) {
      return null;
    }

    const value = localStorage.getItem(`${this._id}|${key}`);
    return value && JSON.parse(value) as T || null;
  }

  public set<T>(key: string, value: T, excludedFields?: string[]) {
    if (!this._isAvailable) {
      return;
    }
    const replacer = excludedFields ? ((key: string, value: any) => excludedFields.indexOf(key) >= 0 ? undefined : value) : undefined;
    localStorage.setItem(`${this._id}|${key}`, JSON.stringify(value, replacer));
  }

  public delete(key: string) {
    if (!this._isAvailable) {
      return;
    }
    localStorage.removeItem(`${this._id}|${key}`);
  }
}
