import { CancelToken } from 'axios';
import { CacheableBaseApi } from './BaseApi';

export default class ShoppingListItems extends CacheableBaseApi<IShoppingListItem> {
  constructor() {
    super('shoppinglistitems', 'shoppingListItemId');
  }

  public async getAllAsync(cancelToken?: CancelToken) {
    if (this.cache) {
      return this.cache.slice(0);
    }
    return this.getListAsync<IShoppingListItem>('shoppinglistitems', null, cancelToken);
  }

  public async createAsync(shoppingListItem: IShoppingListItem) {
    if (this.cache) {
      return this.addToCache(shoppingListItem);
    }

    return this.postAsync<IShoppingListItem>('shoppinglistitems', shoppingListItem);
  }

  public async updateAsync(shoppingListItem: IShoppingListItem) {
    if (this.cache) {
      this.persistCache();
    } else {
      await this.putAsync<IShoppingListItem>('shoppinglistitems', shoppingListItem);
    }
  }

  public async deleteAsync(shoppingListItem: IShoppingListItem) {
    if (this.cache) {
      this.removeFromCache(shoppingListItem);
    } else {
      await this.$deleteAsync('shoppinglistitems/' + shoppingListItem.shoppingListItemId);
    }
  }
}
