import api from '../api';
import { event } from 'vue-gtag';
import { Mutation } from './ModuleFactory';
import assign from 'lodash/assign';
import { CancelToken } from 'axios';

export enum SortOption {
  MostRecent = 'most_recent',
  Oldest = 'oldest',
  Category = 'category',
  Title = 'title'
}

export default class ShoppingListModule {
  private _brDataAppId = null as string;

  private _shoppingLists = [] as IShoppingList[];
  public get shoppingLists() {
    return this._shoppingLists;
  }

  private _selectedShoppingList = null as IShoppingList | null;
  public get selectedShoppingList() {
    return this._selectedShoppingList;
  }
  public set selectedShoppingList(value: IShoppingList) {
    this._selectedShoppingList = value;
  }

  private _shoppingListSortOption = SortOption.Category;
  public get shoppingListSortOption() {
    return this._shoppingListSortOption;
  }
  public set shoppingListSortOption(value: SortOption) {
    this._shoppingListSortOption = value;
  }

  private _showCheckedItems = false;
  public get showCheckedItems() {
    return this._showCheckedItems;
  }
  public set showCheckedItems(value: boolean) {
    this._showCheckedItems = value;
  }

  @Mutation
  private setBRdataAppId(value: string) {
    this._brDataAppId = value;
  }

  @Mutation
  private setShoppingLists(value: IShoppingList[]) {
    this._shoppingLists = value;
  }

  @Mutation
  private addShoppingList(value: IShoppingList) {
    this._shoppingLists.push(value);
  }

  @Mutation
  private updateShoppingList(target: IShoppingList, source: IShoppingList) {
    assign(target, source);
  }

  @Mutation
  private removeShoppingList(value: IShoppingList) {
    this._shoppingLists.splice(this._shoppingLists.indexOf(value), 1);
  }

  @Mutation
  private addShoppingListItem(value: IShoppingListItem) {
    this._selectedShoppingList.items.push(value);
    event('Add', { event_category: 'Shopping List Items', event_label: this._selectedShoppingList.name, value: value.adSpaceId })
  }

  @Mutation
  private removeShoppingListItem(value: IShoppingListItem) {
    this._selectedShoppingList.items.splice(this._selectedShoppingList.items.indexOf(value), 1);
    event('Remove', { event_category: 'Shopping List Items', event_label: this._selectedShoppingList.name, value: value.adSpaceId })
  }

  @Mutation
  private updateShoppingListItem(target: IShoppingListItem, source: IShoppingListItem) {
    assign(target, source);
  }

  public async loadAsync(isFrequentShopper?: boolean, brDataAppId?: string, userOptions?: IUserOptions, cancelToken?: CancelToken) {
    let shoppingLists: IShoppingList[];
    if (isFrequentShopper) {
      this.setBRdataAppId(brDataAppId);
      shoppingLists = await api.brdata.getShoppingListsAsync(brDataAppId, cancelToken);
      if (shoppingLists.length === 0) {
        shoppingLists.push(
          await api.brdata.createShoppingListAsync(brDataAppId, {
            name: 'My Shopping List',
            createdDate: new Date().toJSON(),
            items: []
          } as any, cancelToken));
      }
    } else {
      this.setBRdataAppId(null);
      await api.all(
        api.shoppingLists.getAllAsync(cancelToken),
        api.shoppingListItems.getAllAsync(cancelToken),
        (shoppingListsResult, shoppingListItems) => {
          shoppingLists = shoppingListsResult;
          shoppingLists.forEach(list => list.items = shoppingListItems.filter(item => item.shoppingListId === list.shoppingListId));
        });

      if (shoppingLists.length === 0) {
        shoppingLists.push(
          await api.shoppingLists.createAsync({
            name: 'My Shopping List',
            createdDate: new Date().toJSON(),
            items: []
          } as any));
      }
    }
    this.setShoppingLists(shoppingLists);

    this.selectedShoppingList = this.shoppingLists[0];
    if (userOptions) {
      if (userOptions.selectedShoppingListName) {
        const list = this.shoppingLists.find(sl => sl.name === userOptions.selectedShoppingListName);
        if (list) {
          this.selectedShoppingList = list;
        }
      }
      if (userOptions.showCheckedItems !== undefined) {
        this.showCheckedItems = userOptions.showCheckedItems;
      }
      if (userOptions.shoppingListSortOption) {
        this.shoppingListSortOption = userOptions.shoppingListSortOption as SortOption;
      }
    }
  }

  public async createShoppingListAsync(list: IShoppingList) {
    if (this._brDataAppId) {
      list = await api.brdata.createShoppingListAsync(this._brDataAppId, list);
    } else {
      list = await api.shoppingLists.createAsync(list);
    }
    this.addShoppingList(list);
    this.selectedShoppingList = list;
  }

  public async updateShoppingListAsync(list: IShoppingList, newValues: IShoppingList) {
    this.updateShoppingList(list, newValues);
    await api.shoppingLists.updateAsync(list);
  }

  public async deleteShoppingListAsync(list: IShoppingList) {
    this.removeShoppingList(list);
    if (this._brDataAppId) {
      await api.brdata.deleteShoppingListAsync(this._brDataAppId, list.shoppingListId);
    } else {
      await api.shoppingLists.deleteAsync(list);
    }
  }

  public async emailShoppingListAsync(list: IShoppingList, emailAddress: string, includeImages: boolean, sortOption = SortOption.Category) {
    await api.shoppingLists.emailAsync(list, emailAddress, includeImages, sortOption);
  }

  public async addShoppingListItemAsync(item: IShoppingListItem) {
    item.shoppingListId = this._selectedShoppingList.shoppingListId;
    if (this._brDataAppId) {
      this.addShoppingListItem(item = await api.brdata.createShoppingListItemAsync(this._brDataAppId, item));
    } else {
      this.addShoppingListItem(item = await api.shoppingListItems.createAsync(item));
    }
    return item;
  }

  public async removeShoppingListItemAsync(item: IShoppingListItem) {
    this.removeShoppingListItem(item);
    if (this._brDataAppId) {
      await api.brdata.deleteShoppingListItemAsync(this._brDataAppId, item.shoppingListItemId);
    } else {
      await api.shoppingListItems.deleteAsync(item);
    }
  }

  public async updateShoppingListItemAsync(item: IShoppingListItem, newValues: IShoppingListItem) {
    this.updateShoppingListItem(item, newValues);
    if (this._brDataAppId) {
      await api.brdata.updateShoppingListItemAsync(this._brDataAppId, item);
    } else {
      await api.shoppingListItems.updateAsync(item);
    }
  }
}
