


























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import endsWith from 'lodash/endsWith'

@Component
export default class AppImg extends Vue {
  @Prop({ required: true }) readonly src!: string
  @Prop() readonly alt!: string
  @Prop({ default: '.png,.jpg' }) readonly type!: string
  @Prop({ default: true }) readonly cache!: boolean
  @Prop({ default: true }) readonly showLoading!: boolean
  @Prop({ default: null }) readonly width!: number
  typeIndex = 0
  autoSize = false

  get srcWithExt() {
    if (this.src && !endsWith(this.src, '/')) {
      const ext = this.type.split(',')[this.typeIndex]
      if (ext) {
        if (!this.cache) {
          return this.src + ext + '?nocache=' + +new Date()
        }
        return this.src + ext
      }
    }
    return ''
  }

  onLoaded() {
    if (!this.width) {
      const vImg = this.$children[0] as Vue
      const img = (vImg as any).image as HTMLImageElement
      this.autoSize =
        img &&
        img.naturalWidth < vImg.$el.clientWidth &&
        img.naturalHeight < vImg.$el.clientHeight
    }
    this.$emit('load')
  }
}
