var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-img",
      class: { "app-img--auto-size": _vm.autoSize },
      style: { width: _vm.width + "px" }
    },
    [
      _c(
        "v-img",
        {
          attrs: { src: _vm.srcWithExt, alt: _vm.alt, contain: "" },
          on: {
            error: function($event) {
              _vm.typeIndex++
            },
            load: function($event) {
              return _vm.onLoaded()
            }
          },
          scopedSlots: _vm._u(
            [
              _vm.showLoading
                ? {
                    key: "placeholder",
                    fn: function() {
                      return [
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              "fill-height": "",
                              "align-center": "",
                              "justify-center": "",
                              "ma-0": ""
                            }
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: { indeterminate: "", color: "primary" }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }