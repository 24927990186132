var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { staticClass: "scissors-icon", attrs: { viewBox: "-10 0 170 178" } },
    [
      _vm.title ? _c("title", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
      _c("path", {
        attrs: {
          fill: "currentColor",
          "fill-rule": "evenodd",
          "paint-order": "fill",
          d:
            "m113.5-0.1l2.2 2.8c1.3 1.5 3.4 5 4.8 7.8 1.4 2.8 2.9 7.5 3.5 10.5 0.8 4.1 0.7 6.8-0.3 10.5-1 3.6-6.7 13-20.6 33.5-10.6 15.7-23.6 34.9-38.6 56.9l2.1 4.8c1.2 2.6 2.5 6.3 3.4 11.3h-9.5c-8.1 0-9.6-0.3-10.2-1.7-0.3-1-1.6-3.1-2.7-4.7-1.2-1.7-3.9-3.8-6.1-4.8-2.2-1-5.6-1.8-7.5-1.8-1.9 0-5.4 0.9-7.7 2-2.9 1.4-5.1 3.4-6.8 6.3-1.7 2.8-2.5 5.7-2.5 9 0 2.6 0.6 5.9 1.4 7.5 0.8 1.5 2.7 3.9 4.3 5.4 1.5 1.4 4.7 3.1 7.1 3.7 2.9 0.8 5.5 0.8 8.2 0.1 2.2-0.6 5.4-2.1 7-3.5 2.8-2.3 3.8-2.5 13-2.5 5.5 0 10 0.3 10 0.8 0 0.4-1.4 3-3.2 5.7-1.7 2.8-4.5 6.3-6.2 7.8-1.7 1.6-5.3 4-8.1 5.5-3.9 2-6.8 2.6-13.5 3-7.1 0.3-9.5 0-14.5-1.9-4.3-1.6-7.6-3.8-11.6-7.8-3.8-3.9-6.3-7.5-7.9-11.6-1.8-4.3-2.4-8-2.4-13 0-5.1 0.6-8.6 2.4-13 1.6-3.9 4.2-7.8 7.5-11 2.7-2.8 7.3-6.1 10-7.4 3.1-1.5 7.8-2.6 19.6-3.6zm-48.5 84.5c-1.3 2.2-1.3 2.7 0.3 5 0.9 1.4 2.6 2.8 3.7 3.1 1.1 0.2 3.2-0.2 4.5-1 1.4-0.9 2.8-2.4 3-3.5 0.3-1.1-0.1-3-0.8-4.3-0.7-1.2-2.2-2.5-3.4-2.8-1.1-0.4-2.9-0.3-4 0.2-1 0.4-2.5 1.9-3.3 3.3zm29.2 7.6c0.5 0 3.9 3.8 13.8 17l4.3-0.6c2.3-0.4 6.7-0.2 9.7 0.4 3.2 0.7 8.1 2.8 11.5 5 4 2.5 7.3 5.7 9.7 9.2 2 3 4.4 7.9 5.4 11 1.2 3.8 1.6 7.4 1.2 12-0.2 3.5-1.4 8.6-2.6 11.2-1.2 2.6-4.3 7.1-6.9 9.8-2.7 2.9-7.2 6.3-10.3 7.7-3.7 1.8-7.7 2.8-12.5 3.1-4.7 0.2-8.6-0.1-12-1.2-2.7-0.9-7.2-3.1-9.9-4.9-2.7-1.8-6.1-4.8-7.6-6.7-1.6-2-3.8-5.8-5-8.5-1.3-2.8-2.5-7.9-2.7-11.5-0.4-4.9 0.1-8.1 3.8-19.5l-3.6-4.5c-1.9-2.5-3.4-5-3.1-5.5 0.2-0.6 3.8-6.1 8.1-12.3 4.2-6.2 8.1-11.2 8.7-11.2zm7.4 43.1c-1.3 2.1-2 5.3-2 8.4-0.1 3.9 0.6 6 2.4 8.8 1.4 2 4.1 4.5 6 5.6 2.2 1.2 5.5 2 8.5 2.1 3.6 0 6-0.6 8.5-2.3 2-1.2 4.6-4.3 5.8-6.7 1.2-2.5 2.2-5.9 2.2-7.5 0-1.7-0.9-4.9-2-7.3-1.3-2.9-3.3-5-6.2-6.7-2.8-1.6-5.8-2.5-8.8-2.5q-4.5 0-8.5 2.4c-2.2 1.3-4.8 3.9-5.9 5.7zm-81.7-134.2c0.3 0 10.6 12.3 22.7 27.3 12.1 15 22.4 27.8 22.8 28.5 0.4 0.7-2.7 6.1-7.5 13-4.5 6.5-8.2 11.9-8.3 12 0 0.2-7.7-9.3-17-21-9.3-11.7-17.8-22.8-19-24.7-1.1-2-2.3-5.3-2.7-7.5-0.4-2.2-0.2-6.5 0.3-9.5 0.6-3.1 2.2-7.8 3.6-10.5 1.3-2.8 2.9-5.6 3.5-6.3 0.5-0.7 1.2-1.2 1.6-1.3z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }