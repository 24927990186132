import { CancelToken } from 'axios'
import api from '../api'
import { event } from 'vue-gtag'
import { Mutation } from './ModuleFactory'

export default class FavoritesModule {
  private _favorites = [] as IFavorite[]
  public get favorites() {
    return this._favorites
  }

  @Mutation
  private setFavorites(value: IFavorite[]) {
    this._favorites = value
  }

  @Mutation
  private addFavorite(value: IFavorite) {
    this._favorites.push(value)
    event('Add', { event_category: 'Favorites', value: value.adStudioAssetId })
  }

  @Mutation
  private removeFavorite(value: IFavorite) {
    this._favorites.splice(this._favorites.indexOf(value), 1)
    event('Remove', { event_category: 'Favorites', value: value.adStudioAssetId })
  }

  public async loadAsync(cancelToken?: CancelToken) {
    this.setFavorites(await api.favorites.getAllAsync(cancelToken))
  }

  public async addFavoriteAsync(favorite: IFavorite) {
    const adSpace = favorite.adSpace
    favorite = await api.favorites.createAsync(favorite)
    favorite.adSpace = adSpace
    this.addFavorite(favorite)
  }

  public async removeFavoriteAsync(favorite: IFavorite) {
    this.removeFavorite(favorite)
    await api.favorites.deleteAsync(favorite)
  }
}
