import api from '../api'
import { CancelToken } from 'axios'
import { Mutation } from './ModuleFactory'
import { event } from 'vue-gtag'

export default class CouponModule {
  private _coupons = [] as IBRdataCoupon[]
  public get coupons() {
    return this._coupons
  }

  private _clippedCoupons = [] as IBRdataCoupon[]
  public get clippedCoupons() {
    return this._clippedCoupons
  }

  @Mutation
  private setCoupons(value: IBRdataCoupon[]) {
    this._coupons = value
  }

  @Mutation
  private setClippedCoupons(value: IBRdataCoupon[]) {
    this._clippedCoupons = value
  }

  @Mutation
  private addCoupon(coupon: IBRdataCoupon) {
    this._clippedCoupons.push(coupon)
  }

  public async loadAsync(brDataAppId: string, cancelToken?: CancelToken) {
    const result = await api.brdata.getCouponsAsync(brDataAppId)
    this.setCoupons(result.available || [])
    this.setClippedCoupons(result.activated || [])
  }

  public async clipCouponAsync(brDataAppId: string, coupon: IBRdataCoupon) {
    await api.brdata.addCouponAsync(brDataAppId, [coupon.offerId])
    this.addCoupon(coupon)
    event('Clip', { event_category: 'Coupons', value: coupon.offerId })
  }
}
