var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      staticClass: "pageview-icon v-icon theme--light",
      attrs: { viewBox: "0 0 24 24" }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M12 8.04C9.38 5.54 5.87 4 2 4L2 16.51C5.87 16.51 9.38 18.05 12 20.55C14.62 18.06 18.13 16.51 22 16.51L22 4C18.13 4 14.62 5.54 12 8.04Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }