import { BaseApi } from './BaseApi'

export default class MediaStudio extends BaseApi {

  public async getPlatformsAsync() {
    return await this.getListAsync<IPlatform>('mediaStudio/platforms')
  }

  public async getAccountsAsync(ownerId) {
    return await this.getListAsync<IAccountPlatform>('mediaStudio/accounts/', { ownerId })
  }

  public async disconnectAccountPlatformAsync(accountPlatformId: number) {
    return await this.putAsync(`mediastudio/accountplatforms/${accountPlatformId}/disconnect`)
  }
}
