import Vue from 'vue'
import Vuex from 'vuex'
import { Store as BaseStore } from 'vuex/types'
import ModuleFactory from './ModuleFactory'
import AppModule from './AppModule'
import StoreModule from './StoreModule'
import AdModule from './AdModule'
import ShoppingListModule from './ShoppingListModule'
import CouponModule from './CouponModule'
import UserModule from './UserModule'
import FavoritesModule from './FavoritesModule'
import AdminModule from './AdminModule'
import BannerAdModule from './BannerAdModule'

Vue.use(Vuex)

declare module 'vue/types/vue' {
  export interface Store<S> extends BaseStore<S> {
    readonly appModule: AppModule
    readonly userModule: UserModule
    readonly storeModule: StoreModule
    readonly adModule: AdModule
    readonly shoppingListModule: ShoppingListModule
    readonly favoritesModule: FavoritesModule
    readonly couponModule: CouponModule
    readonly adminModule: AdminModule
    readonly bannerAdModule: BannerAdModule
  }

  interface Vue {
    $store: Store<any>
  }
}

const store = new Vuex.Store({ strict: process.env.NODE_ENV !== 'production' })
const factory = new ModuleFactory(store)
factory.createModule('appModule', AppModule)
factory.createModule('userModule', UserModule)
factory.createModule('storeModule', StoreModule)
factory.createModule('adModule', AdModule)
factory.createModule('shoppingListModule', ShoppingListModule)
factory.createModule('favoritesModule', FavoritesModule)
factory.createModule('couponModule', CouponModule)
factory.createModule('adminModule', AdminModule)
factory.createModule('bannerAdModule', BannerAdModule)
export default store
