import axios, { CancelToken, AxiosRequestConfig } from 'axios';
import LocalStorage from '../LocalStorage';

export const axiosInstance = axios.create({
  baseURL: 'api/'
});

export abstract class BaseApi {
  protected readonly _api = axiosInstance;

  protected async getAsync<T>(url: string, params?: any, cancelToken?: CancelToken) {
    try {
      const response = await this._api.get<T>(url, {
        params: params,
        cancelToken: cancelToken
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return null;
      }
      throw error;
    }
  }

  protected async getListAsync<T>(url: string, params?: any, cancelToken?: CancelToken) {
    const response = await this._api.get<T[]>(url, {
      params: params,
      cancelToken: cancelToken
    });
    return response.data;
  }

  protected async postAsync<T>(url: string, data?: any, params?: any, cancelToken?: CancelToken) {
    const response = await this._api.post<T>(url, data, {
      params: params,
      cancelToken: cancelToken
    });
    return response.data;
  }

  protected async putAsync<T>(url: string, data?: any, params?: any, cancelToken?: CancelToken) {
    const response = await this._api.put<T>(url, data, {
      params: params,
      cancelToken: cancelToken
    });
    return response.data;
  }

  protected async $deleteAsync(url: string, params?: any, cancelToken?: CancelToken) {
    const response = await this._api.delete(url, {
      params: params,
      cancelToken: cancelToken
    });
    return response.data;
  }
}

export abstract class CacheableBaseApi<T> extends BaseApi {
  private _localStorage = null as LocalStorage | null;
  private readonly _cacheKey: string;
  private readonly _idFieldName: string;
  private readonly _excludedFields: string[];
  private _cache = null as T[] | null;
  private _nextId = 0;

  constructor(cacheKey: string, idFieldName: string, excludedFields?: string[]) {
    super();
    this._cacheKey = cacheKey;
    this._idFieldName = idFieldName;
    this._excludedFields = excludedFields;
  }

  protected get cache() {
    return this._cache;
  }

  protected addToCache(item: T) {
    if (!this._cache || !this._localStorage) {
      throw new Error('ERROR: No cache present.');
    }
    (item as any)[this._idFieldName] = this._nextId++;
    this._cache.push(item);
    this._localStorage.set(this._cacheKey, this._cache, this._excludedFields);
    return item;
  }

  protected removeFromCache(item: T) {
    if (!this._cache || !this._localStorage) {
      throw new Error('ERROR: No cache present.');
    }
    this._cache.splice(this._cache.indexOf(item), 1);
    this._localStorage.set(this._cacheKey, this._cache, this._excludedFields);
  }

  protected persistCache() {
    if (!this._cache || !this._localStorage) {
      throw new Error('ERROR: No cache present.');
    }
    this._localStorage.set(this._cacheKey, this._cache, this._excludedFields);
  }

  public resetCache() {
    this.persistCache();
    this._cache = this._localStorage.get(this._cacheKey) || [];
  }

  public useLocalStorage(localStorage: LocalStorage | null) {
    if (!localStorage) {
      if (this._localStorage) {
        this._localStorage.delete(this._cacheKey);
        this._cache = null;
        this._nextId = 0;
      }
    } else {
      this._cache = localStorage.get(this._cacheKey) || [];
      if (this._cache.length > 0) {
        this._nextId = Math.max(...this._cache.map(i => (i as any)[this._idFieldName])) + 1;
      }
    }
    this._localStorage = localStorage;
  }
}

type Constructor<T> = new (...args: any[]) => T;

export function ApiExtensions<T extends Constructor<{}>>(Base: T) {
  return class extends Base {

    public all<T1, T2>(call1: Promise<T1>, call2: Promise<T2>, callBack: (result1: T1, result2: T2) => void): Promise<any>;
    public all<T1, T2, T3>(call1: Promise<T1>, call2: Promise<T2>, call3: Promise<T3>,
      callBack: (result1: T1, result2: T2, result3: T3) => void): Promise<any>;
    public all<T1, T2, T3, T4>(call1: Promise<T1>, call2: Promise<T2>, call3: Promise<T3>, call4: Promise<T4>,
      callBack: (result1: T1, result2: T2, result3: T3, result4: T4) => void): Promise<any>;
    public all<T1, T2, T3, T4, T5>(call1: Promise<T1>, call2: Promise<T2>, call3: Promise<T3>, call4: Promise<T4>, call5: Promise<T5>,
        callBack: (result1: T1, result2: T2, result3: T3, result4: T4, result5: T5) => void): Promise<any>;
    public all(arg1: any, arg2: any, arg3: any, arg4?: any, arg5?: any, arg6?: any) {
      if (arg4) {
        if (arg5) {
          if (arg6) {
            return axios.all([arg1, arg2, arg3, arg4, arg5]).then(axios.spread(arg6));
          }
          return axios.all([arg1, arg2, arg3, arg4]).then(axios.spread(arg5));
        }
        return axios.all([arg1, arg2, arg3]).then(axios.spread(arg4));
      }
      return axios.all([arg1, arg2]).then(axios.spread(arg3));
    }


    public beforeRequest(handler: (config: AxiosRequestConfig) => AxiosRequestConfig) {
      return axiosInstance.interceptors.request.use(handler, undefined);
    }

    public ejectInterceptor(id: number) {
      axiosInstance.interceptors.request.eject(id);
      axiosInstance.interceptors.response.eject(id);
    }

    public onError(handler: (error: any) => void) {
      return axiosInstance.interceptors.response.use(undefined, handler);
    }
  };
}
