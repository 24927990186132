import { render, staticRenderFns } from "./TestPage.vue?vue&type=template&id=37f465d1&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("c:\\source\\adViewer\\AdViewer.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37f465d1')) {
      api.createRecord('37f465d1', component.options)
    } else {
      api.rerender('37f465d1', component.options)
    }
    module.hot.accept("./TestPage.vue?vue&type=template&id=37f465d1&functional=true&", function () {
      api.rerender('37f465d1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "wwwroot/src/views/TestPage.vue"
export default component.exports