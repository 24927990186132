import api from '../api'
import { CancelToken } from 'axios'
import { Mutation } from './ModuleFactory'
import { event } from 'vue-gtag'

export default class BannerAdModule {
  private _ads = [] as IBannerAd[]
  public get ads() {
    return this._ads
  }

  @Mutation
  private setAds(value: IBannerAd[]) {
    this._ads = value
  }

  @Mutation
  private setAdSelected(bannerAd: IBannerAd) {
    bannerAd.selected = true
  }

  @Mutation
  private viewAd(bannerAd: IBannerAd) {
    bannerAd.viewCount++
  }

  public getAd(width: number, height: number) {
    return this._ads.find(a => a.width === width && a.height === height)
  }

  public async loadAsync(cancelToken?: CancelToken) {
    this.setAds(await api.bannerAds.getForViewAsync(cancelToken))
  }

  public selectAd(bannerAd: IBannerAd) {
    this.setAdSelected(bannerAd)
  }

  public async viewAdAsync(bannerAd: IBannerAd) {
    this.viewAd(bannerAd)
    await api.bannerAds.viewAsync(bannerAd.bannerAdId)
    event('View', { event_category: 'Banner Ads', value: bannerAd.bannerAdId })
  }

  public clickAd(bannerAd: IBannerAd) {
    event('Click', { event_category: 'Banner Ads', value: bannerAd.bannerAdId })
  }
}
