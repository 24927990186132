const now = new Date()
const offsetFromMountain =
  (+new Date(now.toLocaleString('en-US', { timeZone: 'America/Denver' })) -
    +now) /
  60000

export default function (value: string | number | Date | null) {
  if (!value) {
    return ''
  }

  if (typeof value === 'string' && !value.includes('T')) {
    value = value.replaceAll('-', '/') // safari
    if (!value.includes(':')) value += ' 00:00:00'
  }
  const date = new Date(value)
  date.setMinutes(date.getMinutes() - offsetFromMountain)
  return date
}
