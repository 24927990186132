import { Mutation } from './ModuleFactory';
import api from '../api';
import assign from 'lodash/assign';

export default class UserModule {
  private _authToken = null as string;
  public get authToken() {
    return this._authToken;
  }

  private _user = null as IUser;
  public get user() {
    return this._user;
  }

  private _userOptions = null as IUserOptions;
  public get userOptions() {
    return this._userOptions;
  }

  private _brDataUser = null as IBRdataUser;
  public get brDataUser() {
    return this._brDataUser;
  }

  private _brDataStore = null as IBRdataStore;
  public get brDataStore() {
    return this._brDataStore;
  }

  private _isSignedIn = false;
  public get isSignedIn() {
    return this._isSignedIn;
  }

  @Mutation
  private setAuthToken(value: string) {
    this._authToken = value;
  }

  @Mutation
  private setUser(value: IUser) {
    this._user = value;
    this._brDataUser = null;
    this._brDataStore = null;
  }

  @Mutation
  private setUserOptions(value: IUserOptions) {
    this._userOptions = value;
  }

  @Mutation
  private setBRdataUser(value: IBRdataUser) {
    this._brDataUser = value;
  }

  @Mutation
  private setBRdataStore(value: IBRdataStore) {
    this._brDataStore = value;
  }

  @Mutation
  private updateUserOptions(value: IUserOptions) {
    assign(this._userOptions, value);
  }

  public async authenticateAsync(forceAnonymous = false) {
    const result = await api.account.authenticateAsync();
    if (result.redirectUrl)
      window.location.href = result.redirectUrl
    this.setAuthToken(result.authToken);
    if (forceAnonymous) {
      this.setUser(null);
    } else {
      this.setUser(result.user || null);
    }

    this.setUserOptions(await api.userOptions.getForCurrentUserAsync() || await api.userOptions.saveAsync({ showIntro: true }));
  }

  public async signInAsync(
    username: string,
    password: string,
    isFrequentShopperEnabled: boolean,
    brDataAppId: string,
    isPersistent: boolean) {

    let result: IAuthenticationResult;
    if (isFrequentShopperEnabled && username.indexOf('@') >= 0) {
      result = await api.brdata.loginAsync(brDataAppId, username, password, isPersistent);
    } else {
      result = await api.account.signInAsync(username, password);
    }
    this.setAuthToken(result.authToken);
    this.setUser(result.user);

  }

  public async signInWithTokenAsync(token: string, jwt: string, brDataAppId: string) {
    let result: IAuthenticationResult;
    if (token.length === 36) {
      result = await api.account.signInWithTokenAsync(token, jwt);
    } else {
      result = await api.brdata.loginWithTokenAsync(brDataAppId, token);
    }
    this.setAuthToken(result.authToken);
    this.setUser(result.user);
  }

  public async signOutAsync() {
    this.setUser(null);
    this.setAuthToken(null);
  }

  public async resetPasswordAsync(username: string) {
    await api.account.resetPasswordAsync(username);
  }

  public async loadBrDataUserAsync(appId: string) {
    this.setBRdataUser(await api.brdata.getUserAsync(appId));
    if (this._brDataUser && this._brDataUser.myStore && this._brDataUser.myStore.myStoreNumber) {
      const stores = await api.brdata.getStoresAsync(appId);
      this.setBRdataStore(stores.find(s => s.storeNumber.trim() === this._brDataUser.myStore.myStoreNumber));
    }
  }

  public async updateUserOptionsAsync(newValues: IUserOptions | any) {
    this.updateUserOptions(newValues);
    await api.userOptions.saveAsync(this.userOptions);
  }
}
