import axios, { CancelTokenSource } from 'axios'
import { axiosInstance, ApiExtensions, CacheableBaseApi } from './BaseApi'
import Ads from './Ads'
import Categories from './Categories'
import Stores from './Stores'
import Account from './Account'
import Favorites from './Favorites'
import ShoppingLists from './ShoppingLists'
import ShoppingListItems from './ShoppingListItems'
import UserOptions from './UserOptions'
import StateProvinces from './StateProvinces'
import Bitly from './Bitly'
import SocialMediaCredentials from './SocialMediaCredentials'
import Facebook from './Facebook'
import SocialMediaMessages from './SocialMediaMessages'
import MediaStudio from './MediaStudio'
import AdSpaces from './AdSpaces'
import GoogleAnalytics from './GoogleAnalytics'
import StoreOptions from './StoreOptions'
import BRdata from './BRdata'
import assign from 'lodash/assign'
import get from 'lodash/get'
import LocalStorage from '../LocalStorage'
import BannerAds from './BannerAds'
import CustomerApps from './CustomerApps'

class Api {
  public get baseURL() {
    return axiosInstance.defaults.baseURL
  }
  public set baseURL(value: string) {
    axiosInstance.defaults.baseURL = value
  }
  public get authToken() {
    const match = /Bearer (.*)/.exec(
      get(axiosInstance.defaults.headers, 'Authorization')
    )
    return (match && match[1]) || undefined
  }
  public set authToken(value: string) {
    assign(axiosInstance.defaults.headers, { Authorization: 'Bearer ' + value })
  }

  public readonly ads = new Ads()
  public readonly adSpaces = new AdSpaces()
  public readonly account = new Account()
  public readonly bitly = new Bitly()
  public readonly brdata = new BRdata()
  public readonly categories = new Categories()
  public readonly customerApps = new CustomerApps()
  public readonly facebook = new Facebook()
  public readonly favorites = new Favorites()
  public readonly socialMediaCredentials = new SocialMediaCredentials()
  public readonly socialMediaMessages = new SocialMediaMessages()
  public readonly mediaStudio = new MediaStudio()
  public readonly shoppingListItems = new ShoppingListItems()
  public readonly shoppingLists = new ShoppingLists()
  public readonly stateProvinces = new StateProvinces()
  public readonly stores = new Stores()
  public readonly storeOptions = new StoreOptions()
  public readonly userOptions = new UserOptions()
  public readonly googleAnalytics = new GoogleAnalytics()
  public readonly bannerAds = new BannerAds()

  public useLocalStorage(localStorage: LocalStorage) {
    for (const prop in this) {
      if (this[prop] instanceof CacheableBaseApi) {
        ;((this[prop] as any) as CacheableBaseApi<any>).useLocalStorage(
          localStorage
        )
      }
    }
  }
}

export default new (ApiExtensions(Api))()

export class CancellationTokenSource implements CancelTokenSource {
  private readonly _source = axios.CancelToken.source()

  public cancel(message?: string) {
    this._source.cancel(message)
  }

  public get token() {
    return this._source.token
  }
}
