import { Component, Vue, Mixins } from 'vue-property-decorator';
import { createSimpleTransition } from 'vuetify/es5/util/helpers';
import AppMixin from './AppMixin';

Vue.component('dialog-right-transition', createSimpleTransition('dialog-right-transition'));

@Component
export default class DialogMixin extends Mixins(AppMixin) {
  isOpen = false;

  get isMobile() {
    return this.app.isMobile;
  }

  get transition() {
    return this.app.isMobile ? 'dialog-right-transition' : 'dialog-transition';
  }

  public close(value?: any) {
    if (Object.keys(this).indexOf('beforeClose') >= 0) {
      const result = (this as any)['beforeClose'](value);
      if (result === false) {
        return;
      } else if (result) {
        if (typeof result.then === 'function') {
          result.then((asyncResult: any) => {
            if (asyncResult === false) {
              return;
            }
            this.isOpen = false;
            this.onClosed(asyncResult || value);
          });
          return;
        } else {
          value = result;
        }
      }
    }
    this.isOpen = false;
    this.onClosed(value);
  }

  protected onClosed(value?: any) {
    this.$emit('closed', value);
  }

  async showAsync<R>() {
    const instance = this.$mount();
    instance.isOpen = true;
    const dialog = (instance.$children[0].$refs.dialog || instance.$children[0].$children[0].$refs.dialog) as Element;
    const { scrollX, scrollY } = window;
    let fixedPosition = false;
    if (this.app.isMobile && (instance.$children[0] as any).fullscreen) {
      if (!document.documentElement.style.position) {
        setTimeout(() => document.documentElement.style.position = 'fixed');
        fixedPosition = true;
      }
    } else if (this.app.isIframe) {
      setTimeout(() => (instance.$children[0] as any).showScroll());
    }

    const onEscape = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        this.close();
      }
    };
    document.addEventListener('keydown', onEscape);

    return new Promise<R>(resolve => {
      instance.$once('closed', (value: R) => {
        if (fixedPosition) {
          document.documentElement.style.position = '';
          window.scrollTo(scrollX, scrollY);
        }
        document.removeEventListener('keydown', onEscape);
        const transitionDuration = parseFloat(window.getComputedStyle(dialog)['transitionDuration']);
        setTimeout(() => {
          instance.$destroy();
          resolve(value);
        }, transitionDuration * 1000);
      });
    });
  }
}
