



























import { Component, Mixins } from 'vue-property-decorator';
import DialogMixin from '../mixins/DialogMixin';

@Component
export default class AdBlockerWarningDialog extends Mixins(DialogMixin) {
  reload() {
    location.reload();
  }
}
