var render = function(_h, _vm) {
  var _c = _vm._c
  return _c("div", [
    _c("h3", [_vm._v("ACE")]),
    _c("ul", [
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "cottons-ace-hardware" }
                }
              }
            },
            [_vm._v("cottons-ace-hardware")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "rossi-building-materials" }
                }
              }
            },
            [_vm._v("rossi-building-materials")]
          )
        ],
        1
      )
    ]),
    _c("h3", [_vm._v("AFS")]),
    _c("ul", [
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: "home", params: { storeRoute: "broulims-driggs" } }
              }
            },
            [_vm._v("broulims-driggs")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "ecomsystems-34240" }
                }
              }
            },
            [_vm._v("ecomsystems-34240")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: "home", params: { storeRoute: "petersons" } }
              }
            },
            [_vm._v("petersons")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "stewarts-roosevelt" }
                }
              }
            },
            [_vm._v("stewarts-roosevelt")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: "home", params: { storeRoute: "d-9-grocery" } }
              }
            },
            [_vm._v("d-9-grocery")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            { attrs: { to: { name: "home", params: { storeRoute: "djs" } } } },
            [_vm._v("djs")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "fresh-market-draper" }
                }
              }
            },
            [_vm._v("fresh-market-draper")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: "home", params: { storeRoute: "maceys-ogden" } }
              }
            },
            [_vm._v("maceys-ogden")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: "home", params: { storeRoute: "maceys-provo" } }
              }
            },
            [_vm._v("maceys-provo")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: "home", params: { storeRoute: "ridleys-midway" } }
              }
            },
            [_vm._v("ridleys-midway")]
          )
        ],
        1
      )
    ]),
    _c("h3", [_vm._v("DA")]),
    _c("ul", [
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: "home", params: { storeRoute: "monroe-hardware" } }
              }
            },
            [_vm._v("monroe-hardware")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "gregory-building-center" }
                }
              }
            },
            [_vm._v("gregory-building-center")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "new-palestine-hardware" }
                }
              }
            },
            [_vm._v("new-palestine-hardware")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "carrollton-farmers-exchange" }
                }
              }
            },
            [_vm._v("carrollton-farmers-exchange")]
          )
        ],
        1
      )
    ]),
    _c("h3", [_vm._v("UNFI")]),
    _c("ul", [
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "yes-organic-market-(dc)" }
                }
              }
            },
            [_vm._v("yes-organic-market-(dc)")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "westerly-natural-market" }
                }
              }
            },
            [_vm._v("westerly-natural-market")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "the-star-store--wa-" }
                }
              }
            },
            [_vm._v("the-star-store--wa-")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "the-market-place-inc-wa" }
                }
              }
            },
            [_vm._v("the-market-place-inc-wa")]
          )
        ],
        1
      )
    ]),
    _c("h3", [_vm._v("NCG")]),
    _c("ul", [
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "dianas-cool-corner" }
                }
              }
            },
            [_vm._v("dianas-cool-corner")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "kellys-health-corner" }
                }
              }
            },
            [_vm._v("kellys-health-corner")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "la-montanita-co-op" }
                }
              }
            },
            [_vm._v("la-montanita-co-op")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "three-rivers-market" }
                }
              }
            },
            [_vm._v("three-rivers-market")]
          )
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: "home",
                  params: { storeRoute: "ukiah-natural-foods-co-op" }
                }
              }
            },
            [_vm._v("ukiah-natural-foods-co-op")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }