import { CancelToken } from 'axios';
import { BaseApi } from './BaseApi';

export default class Facebook extends BaseApi {
  public async getAccessTokenAsync(code: string, cancelToken?: CancelToken) {
    return this.getAsync<string>('facebook/access_token', { code }, cancelToken);
  }

  public async getUserPagesAsync(facebookUserId: string, accessToken: string, cancelToken?: CancelToken) {
    return this.getListAsync<string>(`facebook/${facebookUserId}/pages`, { accessToken }, cancelToken);
  }
}
