import { CancelToken } from 'axios'
import { BaseApi } from './BaseApi'

export default class BannerAds extends BaseApi {
  public async getForViewAsync(cancelToken?: CancelToken) {
    return this.getListAsync<IBannerAd>('banners', cancelToken)
  }

  public async getForAdminAsync(cancelToken?: CancelToken) {
    return this.getListAsync<IBannerAd>('banners/admin', cancelToken)
  }

  public async optOutAsync(bannerAdId: number) {
    await this.putAsync(`banners/${bannerAdId}/opt-out`)
  }

  public async optInAsync(bannerAdId: number) {
    await this.putAsync(`banners/${bannerAdId}/opt-in`)
  }

  public async viewAsync(bannerAdId: number) {
    await this.putAsync(`banners/${bannerAdId}/view`)
  }

  public async clickAsync(bannerAdId: number) {
    await this.getAsync(`banners/${bannerAdId}/click`)
  }
}
