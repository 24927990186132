var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "v-layout",
    {
      staticClass: "page-not-found text-xs-center",
      attrs: {
        column: "",
        "align-center": "",
        "justify-center": "",
        "fill-height": ""
      }
    },
    [
      _c("v-icon", [_vm._v("cancel")]),
      _c("p", { staticClass: "my-2" }, [
        _vm._v("\n    Page not found.\n    "),
        _c("br"),
        _vm._v(
          "Please check your store's website to make sure you are using the correct address.\n  "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }