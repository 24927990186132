














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class FrequentShopperIcon extends Vue {
  @Prop({ default: false }) readonly dark!: boolean
  readonly pathToImages = require.context(
    '../@assets',
    true,
    /\.(png|jpe?g|svg)$/
  )

  get storeOptions() {
    return this.$store.storeModule.storeOptions
  }
}
