import { CancelToken } from 'axios';
import { BaseApi } from './BaseApi';

export default class SocialMediaCredentials extends BaseApi {
  public async getByStoreRouteAsync(cancelToken?: CancelToken) {
    return await this.getAsync<ISocialMediaCredentials>('socialmediacredentials', null, cancelToken);
  }

  public async getTwitterOAuthTokenAsync() {
    return await this.postAsync<string>('socialmediacredentials/twitter/oauth');
  }
  
  public async getFacebookAccessTokenAsync(userToken: string) {
    return await this.getAsync<string>('socialmediacredentials/facebook/accesstoken', { userToken });
  }

  public async getMailchimpOAuthURL() {
    return await this.getAsync<string>('socialmediacredentials/mailchimp/oauth/url');
  }

  public async createAsync(credentials: ISocialMediaCredentials) {
    return await this.postAsync<ISocialMediaCredentials>('socialmediacredentials', credentials);
  }

  public async updateAsync(credentials: ISocialMediaCredentials) {
    await this.putAsync<ISocialMediaCredentials>(`socialmediacredentials/${credentials.credentialId}`, credentials);
  }
}
