












import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ScissorsIcon extends Vue {
  @Prop() title!: string
}
