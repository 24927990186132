import { CancelToken } from 'axios';
import { CacheableBaseApi } from './BaseApi';

export default class Favorites extends CacheableBaseApi<IFavorite> {
  constructor() {
    super('favorites', 'favoriteId');
  }

  public async getAllAsync(cancelToken?: CancelToken) {
    if (this.cache) {
      return this.cache.slice(0);
    }
    return this.getListAsync<IFavorite>('favorites', null, cancelToken);
  }

  public async createAsync(favorite: IFavorite) {
    if (this.cache) {
      return this.addToCache(favorite);
    }

    return this.postAsync<IFavorite>('favorites', favorite);
  }

  public async deleteAsync(favorite: IFavorite) {
    if (this.cache) {
      this.removeFromCache(favorite);
    } else {
      await this.$deleteAsync('favorites/' + favorite.favoriteId);
    }
  }
}
