






import { Component, Vue, Watch, Mixins } from 'vue-property-decorator';
import AdBlockerWarningDialog from './components/AdBlockerWarningDialog.vue';
import api from './api';
import AppMixin from './mixins/AppMixin';
import LocalStorage from './LocalStorage';

@Component
export default class App extends Mixins(AppMixin) {
  titleElement = null as HTMLTitleElement;
  bodyElement = null as HTMLBodyElement;
  isAdBlockerWarningShown = false;
  localStorage = undefined as LocalStorage;

  created() {
    api.onError(async error => {
      if (error.message === 'Network Error') {
        if (!this.isAdBlockerWarningShown) {
          setTimeout(async () => {
            this.isAdBlockerWarningShown = true;
            await new AdBlockerWarningDialog().showAsync();
            this.isAdBlockerWarningShown = false;
          });
        }
      } else if (error.response && error.response.status === 401 && this.$store.userModule.user) {
        await this.$store.userModule.signOutAsync();
      }
      return Promise.reject(error);
    });
    this.app.isMobile = this.$vuetify.breakpoint.xsOnly;
    this.app.isIframe = window !== window.top;
  }

  async mounted() {
    this.titleElement = document.querySelector('title');
    this.bodyElement = document.querySelector('body');
  }

  @Watch('$route.params.storeRoute', { immediate: true })
  onStoreRouteChanged(storeRoute: string) {
    if (!storeRoute) {
      return;
    }
    this.localStorage = new LocalStorage(storeRoute);
    api.baseURL = `${storeRoute}/api`;
    api.authToken = this.localStorage.get<string>('authToken');
    api.useLocalStorage(this.localStorage);
  }

  @Watch('$store.userModule.authToken')
  onAuthTokenChanged(authToken: string) {
    this.localStorage.set('authToken', authToken);
    api.authToken = authToken;
  }

  @Watch('$store.userModule.user')
  onUserChanged(user: IUser) {
    if (user && user.isFrequentShopper) {
      api.useLocalStorage(null);
    } else {
      api.useLocalStorage(this.localStorage);
    }
  }

  @Watch('$vuetify.breakpoint')
  onMobileViewChanged() {
    this.app.isMobile = this.$vuetify.breakpoint.xsOnly;
  }

  @Watch('app.title')
  onTitleChanged() {
    if (this.titleElement) {
      this.titleElement.text = this.app.title;
    }
  }
}
