var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "700px", fullscreen: _vm.$vuetify.breakpoint.xsOnly },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "red darken-2", dark: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-regular" },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("warning")]),
              _vm._v("Ad Blocker Detected\n    ")
            ],
            1
          ),
          _c("v-card-text", [
            _c("p", [
              _vm._v(
                "We have detected that you may have an ad blocker enabled for your browser."
              )
            ]),
            _c("p", [
              _vm._v(
                "To have the best possible experience viewing your store's ads, we recommend you either:"
              )
            ]),
            _c("ul", { staticClass: "mb-3" }, [
              _c("li", [
                _vm._v("Pause your ad blocker while using this site, or")
              ]),
              _c("li", [
                _vm._v("Add “www.ecomadviewer.com” to your permanent whitelist")
              ])
            ]),
            _c("p", [
              _vm._v(
                "After you have adjusted your settings, please refresh the page."
              )
            ]),
            _c("p", { staticClass: "mt-4" }, [
              _vm._v(
                "If you are still having trouble please contact us at (941) 342-8811 and we will be happy to assist you."
              )
            ])
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { flat: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("Continue With Ad Blocker")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "green darken-3" },
                  on: {
                    click: function($event) {
                      return _vm.reload()
                    }
                  }
                },
                [_vm._v("Refresh Page")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }