export default class AppModule {
  private _title = 'AdViewer';
  public get title() {
    return this._title;
  }
  public set title(value: string) {
    this._title = value;
  }

  private _isMobile = false;
  public get isMobile() {
    return this._isMobile;
  }
  public set isMobile(value: boolean) {
    this._isMobile = value;
  }

  private _isIframe = false;
  public get isIframe() {
    return this._isIframe;
  }
  public set isIframe(value: boolean) {
    this._isIframe = value;
  }
}
