import { CancelToken } from 'axios';
import { CacheableBaseApi } from './BaseApi';
import assign from 'lodash/assign';

export default class UserOptions extends CacheableBaseApi<IUserOptions> {
  constructor() {
    super('useroptions', 'userOptionsId');
  }

  public async getForCurrentUserAsync(cancelToken?: CancelToken) {
    if (this.cache) {
      return this.cache[0];
    }
    return this.getAsync<IUserOptions>('useroptions', null, cancelToken);
  }

  public async saveAsync(userOptions: IUserOptions) {
    if (this.cache) {
      if (typeof userOptions.userOptionsId === 'number') {
        assign(this.cache[0], userOptions);
        this.persistCache();
        return userOptions;
      } else {
        return this.addToCache(userOptions);
      }
    }
    return this.postAsync<IUserOptions>('useroptions', userOptions);
  }
}
